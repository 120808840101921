import React from 'react'
import PropTypes from 'prop-types'
import csrfToken from '../src/csrf_token'

class NostoRecommendations extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      recommendationsHtml: null
    }

    this.setFallback = this.setFallback.bind(this)
  }

  componentDidMount() {
    let nostoConnected = false
    let nostoSuccess = false

    if (typeof nostojs !== 'undefined') {

      if (this.props.timeoutFallback) {
        // set nosto products fallback if request is not successfull after 3 seconds
        setTimeout(() => {
          (!nostoConnected || !nostoSuccess) && this.setFallback()
        }, 3000)
      }

      nostojs(api => {
        nostoConnected = true

        api.loadRecommendations()

        .then(result => {
          nostoSuccess = true

          const recommendations = result.recommendations

          if (_.isEmpty(recommendations)) {
            this.setFallback()
          } else {
            this.setProducts(recommendations)
          }
        })
        .catch(error =>  {
          console.log(error)

          this.setFallback()
        })
      })
    } else {
      this.setFallback()
    }
  }

  setFallback() {
    this.removeParentContainer()

    this.removeParentContainerMargin()

    this.setState({ recommendationsHtml: this.props.fallbackPartial })
  }

  removeParentContainer() {
    const containerCssId = this.props.containerCssId
    if (!this.props.fallbackPartial && containerCssId) {
      document.getElementById(containerCssId).style.display = 'none'
    }
  }

  removeParentContainerMargin() {
    const containerCssId = this.props.containerCssId
    if (containerCssId) {
      document.getElementById(containerCssId).style.margin = '0'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const recommendedProductsMarkup = this.state.recommendationsHtml

    if (prevState.recommendationsHtml !== recommendedProductsMarkup) {
      ReactRailsUJS.mountComponents()
    }
  }

  setProducts(recommendationsList) {
    const nostoRecommendationSlotId = this.props.recommendationSlotId
    const nostoData = recommendationsList[nostoRecommendationSlotId]

    const productsIdsUrlPairs = this.parseProductIdsUrlPairs(nostoData)
    const recommendationsTitle = this.parseRecommendationsTitle(nostoData)

    if (productsIdsUrlPairs.length > 0) {
      this.fetchProducts(productsIdsUrlPairs, recommendationsTitle)
    } else {
      this.removeParentContainer()

      this.setState({ recommendationsHtml: this.props.fallbackPartial })
    }
  }

  fetchProducts(productsIdsUrlPairs, recommendationsTitle) {
    $.ajax({
      url: '/api/v1/products',
      dataType: 'html',
      method: 'get',
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      data: {
        idsUrlPairs: productsIdsUrlPairs,
        nosto_slot_id: this.props.recommendationSlotId,
        partial_name: this.props.partialName,
        recommendations_title: recommendationsTitle
      },

      success: function(productsPartial) {
        this.setState({ recommendationsHtml: productsPartial })
      }.bind(this),

      error: function(xhr, status, err) {
        console.log(err)

        this.setState({ recommendationsHtml: this.props.fallbackPartial })
      }.bind(this)
    });
  }

  parseProductIdsUrlPairs(nostoData) {
    return ((nostoData !== undefined) && JSON.parse(nostoData).productIdUrlPairs) || []
  }

  parseRecommendationsTitle(nostoData) {
    return (nostoData && JSON.parse(nostoData).recommendationsTitle) || null
  }

  render() {
    const recommendedProductsMarkup = this.state.recommendationsHtml

    return (
      <div>
        { recommendedProductsMarkup &&
          <div dangerouslySetInnerHTML={{__html: recommendedProductsMarkup}}></div>
        }
      </div>
    )
  }
}

NostoRecommendations.propTypes = {
  recommendationSlotId: PropTypes.string.isRequired,
  partialName: PropTypes.string.isRequired,
  fallbackPartial: PropTypes.string,
  timeoutFallback: PropTypes.bool,
}

export default NostoRecommendations
